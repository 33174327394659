import React from 'react';
import classNames from 'classnames';
import Fade from 'react-reveal/Fade';


const SectionTwo = ({
  page = [],
  onClick,
  active,
}) => {
  return (
    <section
      className={classNames('ss_section_two spacer_top', onClick && 'ss_section_two_category')}>
      <div className="container-fluid">
        <div className="ss_two"> 
          <div className="row align-items-stretch">
            {page.map((feature, i) => (
              <Fade key={i}>
                <div
                  className={classNames(
                    'col-sm-6 d-flex align-items-stretch',
                    (onClick ? 'col-md-4 pointer' : 'col-md-3'),
                    (active -1 === i && 'active'),
                  )}
                  onClick={() => {
                    if(onClick) {
                      onClick({ feature, index: i+1 })
                    }
                  }}
                >
                  <div className="ss_two_sec w-100">
                    <div className="row">
                      <div className={onClick ? 'col-3' : 'col-12'}>
                        <img className="img-fluid" src={feature.image} alt="feature" />
                      </div>
                      <div className={onClick ? 'col-9' : 'col-12'}>
                        <h2>{feature.title}</h2>
                        <p>{feature.description}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </Fade>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
}

export default SectionTwo;
